<template>
  <div>
    <v-row>
      <v-col cols="6" md="3">
        <v-btn
          block
          :color="tab === 'generate-uid' ? 'primary' : 'default'"
          @click="changeTab('generate-uid')"
        >
          1. Cấp UID
        </v-btn>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn
          block
          :color="tab === 'assign-uid' ? 'primary' : 'default'"
          @click="changeTab('assign-uid')"
        >
          2. Gắn UID
        </v-btn>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn
          block
          :color="tab === 'active-uid' ? 'primary' : 'default'"
          @click="changeTab('active-uid')"
        >
          3. Active UID
        </v-btn>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn
          block
          :color="tab === 'process-file' ? 'primary' : 'default'"
          @click="changeTab('process-file')"
        >
          4. Xử lý file nhà máy
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <GenerateUid v-if="tab == 'generate-uid'" />
      <AssignUid v-if="tab == 'assign-uid'" />
      <ActiveUid v-if="tab == 'active-uid'" />
      <ProcessFile v-if="tab == 'process-file'" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DcUid",
  components: {
    GenerateUid: () => import("@/components/goods/GenerateUid"),
    AssignUid: () => import("@/components/goods/AssignUid"),
    ActiveUid: () => import("@/components/goods/ActiveUid"),
    ProcessFile: () => import("@/components/goods/ProcessFile"),
  },
  data: () => ({
    tab: "generate-uid",
  }),
  computed: {},
  mounted() {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
};
</script>
